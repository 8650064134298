import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { sectionsId } from 'layouts/StartTemplate/Navigation/Navigation';
import devices from 'theme/devices';

const query = graphql`
  {
    allFile(filter: {name:{regex: "/tellastory|bib-logo-white/"} }) {
      nodes {
        name
        publicURL
      }
    }
  }
`;

interface TellAStoryProps {
   headline: {
      top: string;
      bottom: string;
   };
   meet: any;
   contactPerson: {
      name: string;
      phone: string;
   };
   agency: {
      name: string;
      address: string;
   };
}

function TellAStory({
  contactPerson,
  headline,
  agency,
  meet,
}: TellAStoryProps) {
  const {
    allFile: { nodes: images },
  } = useStaticQuery<{allFile: { nodes: { name: string; publicURL: string; }[] } }>(query);

  return (
    <Wrapper id={sectionsId.tellAStory}>
      <InnerWrapper>
        <SectionHeader>
          <Image src={images.find((img) => img.name === 'tellastory')?.publicURL || ''} alt="" />
          <Headline>
            <HeadlineTellAStory url={images.find((img) => img.name === 'mask-tellastory')?.publicURL || ''}>
              <span>{headline.top}</span>
            </HeadlineTellAStory>
            <HeadlineWatchItWork url={images.find((img) => img.name === 'mask-tellastory')?.publicURL || ''}>
              <span>{headline.bottom}</span>
            </HeadlineWatchItWork>
          </Headline>
          <Email href="mailto:hello@blackisblack.pl">hello@blackisblack.pl</Email>
          <GetToKnowEachOther>
            <StructuredText data={meet} />
          </GetToKnowEachOther>
        </SectionHeader>
        <SectionContact>
          <Contact>
            <ContactColumn>
              <ContactItem>{contactPerson.name}</ContactItem>
              <ContactItem>{contactPerson.phone}</ContactItem>
            </ContactColumn>
            <ContactColumn>
              <ContactItem>{agency.name}</ContactItem>
              <ContactItem>{agency.address}</ContactItem>
            </ContactColumn>
          </Contact>
          <Footer>
            <Logo src={images.find((img) => img.name === 'bib-logo-white')?.publicURL || ''} />
            <Copy>&copy;</Copy>
            <Year>{(new Date()).getFullYear()}</Year>
          </Footer>
        </SectionContact>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  z-index: 10;
  background: #000;

  ${devices.desktop} {
    height: 100vh;
  }
`;

const InnerWrapper = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

const Image = styled.img`
  display: block;
  position: absolute;
  top: 75%;
  right: -30%;
  z-index: -1;
  width: 120%;

  @media(orientation: landscape) {
    width: 70%;
    right: 0;
  }

  ${devices.tabletXL} {
    @media(orientation: landscape) {
      top: 65%;
      width: 65%;
      right: 0;
    }
  }

  ${devices.desktop} {
    top: 74%;
    width: 66%;
    right: -5%;
  }
`;

const Logo = styled.img`
  display: block;
  width: 24px;

  ${devices.mobile} {
    width: 27px;
  }
`;

const SectionContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  ${devices.mobile} {
    margin-top: 60px;
  }

  ${devices.tabletXL} {
    margin-top: 80px;
  }

  ${devices.desktop} {
    align-items: basline;
    max-width: 1200px;
    width: 100%;
    margin: 120px auto 0;
  }
`;

const Contact = styled.div`
  padding: 20px;

  ${devices.mobile} {
    padding: 40px;
  }

  ${devices.tabletL} {
    display: flex;
    padding: 60px;
  }

  ${devices.desktop} {
    padding: 0 0 75px;
  }
`;

const Footer = styled.div`
  display:flex;
  align-items: center;
  color: ${({ theme }) => theme.white};
  padding: 20px;

  ${devices.mobile} {
    padding: 40px;
  }

  ${devices.tabletL} {
    padding: 60px 120px 60px 60px;
  }

  ${devices.desktop} {
    padding: 0 100px 75px 0;
  }

  ${devices.desktopHD} {
    padding: 0 0 75px 0;
  }
`;

const Copy = styled.span`
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-left: 8.8px;

  ${devices.mobile} {
    font-size: 20px;
  }
`;

const Year = styled.span`
  font-size: 12px;
  font-weight: bold;
  display:block;
  margin-left: 6px;

  ${devices.mobile} {
    font-size: 15px;
  }
`;

const ContactColumn = styled.div`
  &:first-of-type {
    margin-bottom: 10px;
  }

  ${devices.tabletL} {
    &:first-of-type {
      margin-bottom: 0;
      margin-right: 60px;
    }
  }

  ${devices.desktop} {
    &:first-of-type {
      margin-right: 143px;
    }
  }
`;

const ContactItem = styled.span`
  display: block;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.2;
  color: ${({ theme }) => theme.white};

  ${devices.mobile} {
    font-size: 18px;
  }
`;

const SectionHeader = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 60px 20px 20px;

  ${devices.mobile} {
    padding: 80px 40px 40px;
  }

  ${devices.tabletL} {
    padding: 40px 60px 60px;
  }

  ${devices.desktop} {
    padding: 0;
    margin: 0 auto;
  }
`;

const Headline = styled.h2`
  display: inline;
  font-size: 11.5vw;
  line-height: 0.8;
  color: ${({ theme }) => theme.white};
  margin: 0 0 0 0.02em;
  font-weight: bold;

  ${devices.desktop} {
    font-size: 177px;
  }
`;

const HeadlineTellAStory = styled.span<{url: string;}>`
  display: block;

  > span {
    display: inline;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    color: transparent;
    background-repeat: no-repeat;
    background-position: 0 -20%;
    background-size: 150%;
    background-image: ${({ url }) => `url(${url})`};
  }
`;

const HeadlineWatchItWork = styled.span<{url: string;}>`
  display: block;

  > span {
    display: inline;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    color: transparent;
    background-repeat: no-repeat; 
    background-position: 0 60%;
    background-size: 130%;
    background-image: ${({ url }) => `url(${url})`};
  }
`;

const Email = styled.a`
  display: block;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.white};
  font-size: 7.5vw;
  text-decoration: none;
  font-weight: bold;
  line-height: 1;

  ${devices.desktop} {
    font-size: 114px;
  }
`;

const GetToKnowEachOther = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  margin: 0 0 0 0.25em;

  > p {
    margin: 20px 0 0;
  }

  ${devices.mobile} {
    font-size: 20px;
  }

  ${devices.mobileL} {
    font-size: 24px;
  }
`;

export default TellAStory;
