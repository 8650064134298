/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
   {
      allFile(filter: {name: {regex: "/animation-intro-/"}}, sort: {fields: name}) {
         nodes {
            publicURL
            id
         }
      }
      file(name: {eq: "logo-animation"}) {
         publicURL
      }       
   }
`;

interface AnimationProps {
   endIntroAnimation: () => void;
}

function Animation({ endIntroAnimation }:AnimationProps) {
  const { allFile: { nodes }, file } = useStaticQuery(query);

  useEffect(() => {
    document.body.classList.add('noScroll');
    document.documentElement.classList.add('scroll');
  }, []);

  return (
    <div>
      <LogoWrapper>
        <LogoImage src={file.publicURL} alt="logo" />
      </LogoWrapper>
      {nodes.map((node: any, index: number) => (
        <Image
          src={node.publicURL}
          alt=""
          key={node.id}
          delay={index + 4}
          onAnimationEnd={() => {
            if (nodes.length - 1 === index) {
              endIntroAnimation();
              document.body.classList.remove('noScroll');
              document.documentElement.classList.remove('scroll');
            }
          }}
        />
      ))}
    </div>
  );
}

const outro = keyframes`
   from {
      opacity: 1;
   }

   to {
      opacity: 0;
   }
`;

const LogoWrapper = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   bottom:0;
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2000;
   background: #000;
   animation: ${outro} 0.25s 1.5s forwards;
`;

const LogoImage = styled.img`
   display: block;
   width: 60px;
`;

const Image = styled.img<{delay: number}>`
   display: block;
   width: 100%;
   height: 100vh;
   object-fit: cover;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: ${({ delay }) => 1000 - delay};
   animation: ${outro} 0.2s ${({ delay }) => `${delay / 2}s`} forwards;
`;

export default Animation;
