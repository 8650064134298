import React, { useEffect, useState } from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import devices from 'theme/devices';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { sectionsId } from 'layouts/StartTemplate/Navigation/Navigation';

const query = graphql`
  {
    allFile(filter: {name: {regex: "/arrow/"}}) {
      nodes {
        publicURL
      }
    }
  }
`;

interface PortfolioProps {
  slides: {
    id: string;
    title: string;
    slug: string;
    cover: {
      alt: string;
      image: IGatsbyImageData;
    }
  }[];
}

function Portfolio({ slides }: PortfolioProps) {
  const { allFile } = useStaticQuery(query);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [numberOfSlides, setNumberOfSlides] = useState(5.5);

  const handleResize = () => {
    if (slides.length === 1) {
      setNumberOfSlides(1);
    } else if (slides.length === 2) {
      if (window.innerWidth < 560) {
        setNumberOfSlides(1);
      } else {
        setNumberOfSlides(2);
      }
    } else if (slides.length === 3) {
      if (window.innerWidth < 560) {
        setNumberOfSlides(1);
      } else if (window.innerWidth < 768) {
        setNumberOfSlides(1.5);
      } else if (window.innerWidth < 1200) {
        setNumberOfSlides(2.5);
      } else {
        setNumberOfSlides(3);
      }
    } else if (slides.length === 4) {
      if (window.innerWidth < 560) {
        setNumberOfSlides(1);
      } else if (window.innerWidth < 768) {
        setNumberOfSlides(1.5);
      } else if (window.innerWidth < 1024) {
        setNumberOfSlides(2.5);
      } else if (window.innerWidth < 1200) {
        setNumberOfSlides(3.5);
      } else {
        setNumberOfSlides(4);
      }
    } else if (slides.length === 5) {
      if (window.innerWidth < 560) {
        setNumberOfSlides(1);
      } else if (window.innerWidth < 768) {
        setNumberOfSlides(1.5);
      } else if (window.innerWidth < 1024) {
        setNumberOfSlides(2.5);
      } else if (window.innerWidth < 1200) {
        setNumberOfSlides(3.5);
      } else if (window.innerWidth < 1400) {
        setNumberOfSlides(4.5);
      } else {
        setNumberOfSlides(5);
      }
    } else {
      // eslint-disable-next-line
      if (window.innerWidth < 560) {
        setNumberOfSlides(1);
      } else if (window.innerWidth < 768) {
        setNumberOfSlides(1.5);
      } else if (window.innerWidth < 1024) {
        setNumberOfSlides(2.5);
      } else if (window.innerWidth < 1200) {
        setNumberOfSlides(3.5);
      } else if (window.innerWidth < 1400) {
        setNumberOfSlides(4.5);
      } else {
        setNumberOfSlides(5.5);
      }
    }

    setCurrentSlide(0);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clickLeft = () => {
    if ((slides.length - currentSlide) === numberOfSlides) {
      setCurrentSlide((prev) => (prev - numberOfSlides < 0 ? 0 : prev - numberOfSlides));
    } else {
      setCurrentSlide((prev) => (
        (prev - Math.floor(numberOfSlides) - Math.floor(numberOfSlides)) >= 0
          ? prev - Math.floor(numberOfSlides)
          : 0));
    }
  };

  const clickRight = () => {
    const postLength = slides.length;
    const postBehindRightScreen = postLength - currentSlide - Math.floor(numberOfSlides);

    if (postBehindRightScreen < numberOfSlides) {
      const move = numberOfSlides - postBehindRightScreen
        + (numberOfSlides % Math.floor(numberOfSlides));

      setCurrentSlide((prev) => prev + numberOfSlides - move);
    } else {
      setCurrentSlide((prev) => prev + Math.floor(numberOfSlides));
    }
  };

  if (slides.length === 0) {
    return null;
  }

  return (
    <Wrapper id={sectionsId.portfolio}>
      <Slider>
        {currentSlide > 0 && (
          <LeftButton onClick={clickLeft}>
            <Image
              src={allFile.nodes.find((node: any) => node.publicURL.includes('left'))?.publicURL}
              alt=""
            />
          </LeftButton>
        )}
        <SliderList currentSlide={currentSlide} numberOfSlides={numberOfSlides}>
          {slides.map((slide, index) => (
            <Slide key={slide.id} numberOfSlides={numberOfSlides}>
              <StyledLink to={slide.slug}>
                {currentSlide !== index && <Blur />}
                <StyledGatsbyImage
                  image={slide.cover.image}
                  alt={slide.cover.alt}
                />
                <Hover>
                  <Description centered={allFile.nodes.length <= 2}>{slide.title}</Description>
                </Hover>
              </StyledLink>
            </Slide>
          ))}
        </SliderList>
        {(slides.length - currentSlide) !== numberOfSlides && (
        <RightButton onClick={clickRight}>
          <Image
            src={allFile.nodes.find((node: any) => node.publicURL.includes('right'))?.publicURL}
            alt=""
          />
        </RightButton>
        )}
      </Slider>
    </Wrapper>
  );
}

const Button = styled.button`
  display: block;
  position: absolute;
  height: 48px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transform: translateY(-50%) scale(1);
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: translateY(-50%) scale(1.1);
  }

  ${devices.tabletXL} {
    height: 60px;
  }
`;

const LeftButton = styled(Button)`
  left: 20px;
  top: 50%;
  z-index: 1;
`;

const RightButton = styled(Button)`
  right: 20px;
  top: 50%;
  z-index: 1;
`;

const Image = styled.img`
  display: block;
  height: 100%;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 100vh;
  width: 100%;
`;

const Description = styled.h2<{centered: boolean}>`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.bold};
  transition: all 0.35s ease-in-out;
  text-align: ${({ centered }) => (centered ? 'center' : 'right')};
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  width: 100%;
`;

const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  background: rgba(0, 0, 0, 0.9);
  color: ${({ theme }) => theme.white};
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
`;

const Wrapper = styled.section``;

const Slider = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
`;

const SliderList = styled.ul<{currentSlide: number; numberOfSlides: number;}>`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: ${({ theme }) => theme.white};
  height: 100vh;
  transform: ${({ currentSlide, numberOfSlides }) => {
    const movement = (100 / (numberOfSlides)) * currentSlide;
    return `translateX(-${movement}%)`;
  }};
  transition: transform 0.35s;
`;

const Slide = styled.li<{numberOfSlides: number;}>`
  display: flex;
  flex-shrink: 0;
  width: ${({ numberOfSlides }) => `calc(100% / ${numberOfSlides})`};
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: inset 100px 0px 67px -67px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 100px 0px 67px -67px rgba(0, 0, 0, 1);
  box-shadow: inset 100px 0px 67px -67px rgba(0, 0, 0, 1);
  z-index:1;
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 100%;
  position: relative;
  color: #fff;

  &:hover {
    ${Hover} {
      opacity: 1;
    }
  }
`;

export default Portfolio;
