import React, { useState, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { sectionsId } from 'layouts/StartTemplate/Navigation/Navigation';
import devices from 'theme/devices';

const query = graphql`
   {
      file(name: {eq: "intro"}) {
         publicURL
      }
   }
`;

interface Query {
   file: {
      publicURL: string;
   }
}

interface StartProps {
   withAnimation: boolean;
}

const shapeAnimationStates = {
  start: 'start',
  progress: 'progress',
  end: 'end',
} as const;

function Start({ withAnimation }: StartProps) {
  const { file } = useStaticQuery<Query>(query);
  const [shapeAnimation, setShapeAnimation] = useState<string>(shapeAnimationStates.start);
  const shapeRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleAnimation = () => {
    if (!ref.current || !shapeRef.current) return;
    if (window.scrollY <= 0) {
      setShapeAnimation(shapeAnimationStates.start);
    } else if (window.scrollY >= ref.current.clientHeight - shapeRef.current.clientHeight) {
      setShapeAnimation(shapeAnimationStates.end);
    } else {
      setShapeAnimation(shapeAnimationStates.progress);
    }
  };

  useEffect(() => {
    handleAnimation();

    document.addEventListener('scroll', handleAnimation);
    window.addEventListener('resize', handleAnimation);
    return () => {
      document.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', handleAnimation);
    };
  }, [ref, shapeRef]);

  return (
    <Wrapper id={sectionsId.start} withAnimation={withAnimation}>
      <ImageWrapper ref={ref}>
        <Shape shapeAnimation={shapeAnimation} ref={shapeRef} />
        <Image src={file.publicURL} alt="" />
      </ImageWrapper>
    </Wrapper>
  );
}

const ImageWrapper = styled.div`
   position: relative;
   width: calc(100% - 40px);
   margin: 0 auto;

   ${devices.mobile} {
      width: calc(100% - 60px);
   }

   ${devices.mobileL} {
      width: calc(100% - 80px);
   }

   ${devices.desktop} {
      max-width: 1389px;
      width: calc(100% - 120px);
   }

   ${devices.desktopHD} {
      max-width: 1389px;
      width: 100%;
   }
`;

const intro = keyframes`
   from {
      transform: translateY(-100%);
   }

   to {
      transform: translateY(0);
   }
`;

const navbarHeight = '78px';

const Wrapper = styled.div<{withAnimation: boolean}>`
   padding: calc(16.5vw + ${navbarHeight}) 0;

   ${({ withAnimation }) => withAnimation && css`
      animation: ${intro} 2s 4s forwards;
   `}

   ${devices.desktop} {
      padding: 14.95vw 0;
   }
`;

const Shape = styled.div<{shapeAnimation: string}>`
   width: 100%;
   height: 16.5vw;
   background: #fff;
   mix-blend-mode: difference;

   ${devices.tablet} {
      height: 17vw;
   }

   ${devices.tabletXL} {
      height: 17.5vw;
   }

   ${devices.desktop} {
      height: 17vw;
   }

   ${devices.desktopHD} {
      height: 262px;
   }

   ${({ shapeAnimation }) => {
    switch (shapeAnimation) {
      case shapeAnimationStates.start:
        return css`
         position: absolute;
         top: 0;
         left: 50%;
         transform: translateX(-50%);
        `;
      case shapeAnimationStates.end:
        return css`
         position: absolute;
         bottom: 0;
         left: 50%;
         transform: translateX(-50%);
        `;
      case shapeAnimationStates.progress:
      default:
        return css`
         position: fixed;
         top: calc(16.5vw + ${navbarHeight});
         left: 50%;
         width: calc(100% - 40px);
         transform: translateX(-50%);

         ${devices.mobile} {
            width: calc(100% - 60px);
         }

         ${devices.mobileL} {
            width: calc(100% - 80px);
         }

         ${devices.desktop} {
            max-width: 1389px;
            width: calc(100% - 120px);
            top: 14.95vw;
         }

         ${devices.desktopHD} {
            width: 100%;
         }
        `;
    }
  }}
`;

const Image = styled.img`
   display: block;
   width: 100%;
   
   ${devices.desktop} {
      max-width: 1389px;
   }
`;

export default Start;
