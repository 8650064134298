import React, { useEffect, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import { scroller } from 'react-scroll';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import Screenreader from 'components/ScreenReader';

import { Helmet } from 'react-helmet';
import { StateProps, State } from '..';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import Navigation from './Navigation/Navigation';
import TellAStory from './TellAStory/TellAStory';
import Portfolio from './Portfolio/Portfolio';
import GetBlack from './GetBlack/GetBlack';
import WhyBlack from './WhyBlack/WhyBlack';
import Animation from './Animation/Animation';
import Start from './Start/Start';

interface StartTemplateProps {
  location: {
    state: {
      fromCasePage?: boolean;
    }
  }
  data: {
    datoCmsHome: any;
    allDatoCmsPortfolio: {
      nodes: {
        slug: string;
        order: number;
        originalId: string;
        slideTitle: string;
        slideCover: {
          alt: string;
          gatsbyImageData: ImageDataLike;
        }
      }[];
    }
  }
}

function StartTemplate({ data, ...rest } : StartTemplateProps) {
  const context = useContext<StateProps>(State);
  const [introAnimation, setIntroAnimation] = useState(context.introAnimation);
  const [navAnimation, setNavAnimation] = useState(context.introAnimation);
  const [mobileNavigation, setMobileNavigation] = useState(false);

  const handleResize = () => {
    setMobileNavigation(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const endIntroAnimation = () => {
    setIntroAnimation(false);
    context.turnOffIntroAnimation();
  };

  const endNavAnimation = () => {
    setNavAnimation(false);
  };

  useEffect(() => {
    if (rest.location.state?.fromCasePage) {
      scroller.scrollTo('portfolio', {
        duration: 400,
        smooth: true,
      });

      window.history.replaceState({ ...rest.location.state, fromCasePage: false }, '');
    }
  }, []);

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: data.datoCmsHome.lang,
        }}
        title={data.datoCmsHome.title}
        meta={[
          {
            name: 'description',
            content: data.datoCmsHome.pageDescription,
          },
          {
            name: 'author',
            content: 'Black is Black',
          },
        ]}
      />
      {introAnimation && (
        <Animation
          endIntroAnimation={endIntroAnimation}
        />
      )}
      <Screenreader as="h1">{data.datoCmsHome.title}</Screenreader>
      {mobileNavigation ? (
        <MobileNavigation
          withAnimation={navAnimation}
          enNavAnimation={endNavAnimation}
          noPortfolioCases={data.allDatoCmsPortfolio.nodes.length === 0}
          langButton={data.datoCmsHome.langButton}
          navGetBlack={data.datoCmsHome.navGetBlack}
          navPortfolio={data.datoCmsHome.navPortfolio}
          navTellAStory={data.datoCmsHome.navTellAStory}
          navWhyBlack={data.datoCmsHome.navWhyBlack}
          lang={data.datoCmsHome.lang}
        />
      )
        : (
          <Navigation
            withAnimation={navAnimation}
            enNavAnimation={endNavAnimation}
            noPortfolioCases={data.allDatoCmsPortfolio.nodes.length === 0}
            langButton={data.datoCmsHome.langButton}
            navGetBlack={data.datoCmsHome.navGetBlack}
            navPortfolio={data.datoCmsHome.navPortfolio}
            navTellAStory={data.datoCmsHome.navTellAStory}
            navWhyBlack={data.datoCmsHome.navWhyBlack}
            lang={data.datoCmsHome.lang}
          />
        )}
      <Start withAnimation={navAnimation} />
      <GetBlack
        intro={data.datoCmsHome.intro}
        creativeDirectors={{
          names: data.datoCmsHome.creativeDirectorsNames,
          position: data.datoCmsHome.creativeDirectorsPosition,
        }}
      />
      <Portfolio
        slides={data.allDatoCmsPortfolio.nodes.map((node) => ({
          title: node.slideTitle,
          id: node.originalId,
          slug: data.datoCmsHome.lang === 'en' ? `/en/${node.slug}` : node.slug,
          cover: {
            alt: node.slideCover.alt,
            image: getImage(node.slideCover.gatsbyImageData) as IGatsbyImageData,
          },
        }))}
      />
      <WhyBlack
        cityConnection={data.datoCmsHome.cityConnection}
        noBullshit={data.datoCmsHome.noBullshit}
      />
      <TellAStory
        headline={{
          top: data.datoCmsHome.headlineTop,
          bottom: data.datoCmsHome.headlineBottom,
        }}
        meet={data.datoCmsHome.meet}
        contactPerson={{
          name: data.datoCmsHome.contactPersonName,
          phone: data.datoCmsHome.contactPersonNumber,
        }}
        agency={{
          name: data.datoCmsHome.agencyName,
          address: data.datoCmsHome.agencyLocation,
        }}
      />
    </div>
  );
}

export const query = graphql`
  query Home($originalId: String, $lang: String) {
    datoCmsHome(originalId: {eq: $originalId}) {
      title
      pageDescription
      navGetBlack
      navPortfolio
      navTellAStory
      navWhyBlack
      agencyLocation
      agencyName
      contactPersonName
      contactPersonNumber
      noBullshit {
        value
        blocks
      }
      langButton
      lang
      intro {
        blocks
        value
      }
      headlineTop
      headlineBottom
      id
      email
      creativeDirectorsPosition
      creativeDirectorsNames
      cityConnection {
        blocks
        value
      }
      meet {
        blocks
        value
      }
      originalId
      title
    }
    allDatoCmsPortfolio(filter: {lang: {eq: $lang}}, sort: {fields: order}) {
      nodes {
        slug
        order
        originalId
        slideTitle
        slideCover {
          alt
          gatsbyImageData(width: 540)
        }
      }
    }
  }
`;

export default StartTemplate;
