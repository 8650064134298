import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import devices from 'theme/devices';
import { sectionsId } from 'layouts/StartTemplate/Navigation/Navigation';

const query = graphql`
  {
      file(name: {regex: "/get-black-background/"}) {
         childImageSharp {
            gatsbyImageData(quality: 100)
         }
      }
  }
`;

interface GetBlackProps {
   intro: any;
   creativeDirectors: {
      names: string;
      position: string;
   }
}

function GetBlack({ intro, creativeDirectors }: GetBlackProps) {
  const { file } = useStaticQuery(query);

  return (
    <Wrapper id={sectionsId.getBlack}>
      <StyledGatsbyImage image={getImage(file.childImageSharp.gatsbyImageData) as IGatsbyImageData} alt="" />
      <Content>
        <StyledParagraph>
          <StructuredText data={intro} />
        </StyledParagraph>
        <Authors id="getBlackAuthors">
          {creativeDirectors.names}
          {' '}
          <Brake>{creativeDirectors.position}</Brake>
        </Authors>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.section`
   position: relative;
   display: block;
   width: 100%;
   min-height: 100vh;
   padding: 0;
   margin: 0;
   z-index: 10;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 80px 80px 80px 20px;

  ${devices.mobile} {
    padding: 100px 100px 100px 30px;
  }

  ${devices.mobileL} {
    padding: 100px 100px 100px 40px;
  }

  ${devices.tabletL} {
    padding: 120px 20vw 120px 40px;
  }

  ${devices.tabletXL} {
    padding: 120px 16vw 120px 60px;
  }

  ${devices.desktop} {
    padding: 120px 16vw 120px 100px;
  }

  ${devices.desktopHD} {
    padding: 160px 20vw 160px 160px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   width: 100%;
`;

const Brake = styled.span`
  display: block;

  ${devices.mobile} {
    display: inline-block;
  }
`;

const Authors = styled.p`
  display: block;
  margin: 20px 0 0;
  font-size: 14px;
  text-align: right;
  font-weight: 400;
  color: #fff;

  ${devices.mobile} {
    margin-top: 30px;
  }

  ${devices.tabletL} {
    margin: 40px 0 0;
  }
`;

const StyledParagraph = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-align: justify;
  text-align-last: justify;

  ${devices.mobile} {
    font-size: 20px;
  }

  ${devices.tabletL} {
    font-size: 26px; 
  }

  ${devices.tabletXL} {
    font-size: 32px; 
  }

  ${devices.desktop} {
    font-size: 36px; 
  }

  ${devices.desktopHD} {
    font-size: 40px; 
  }

  span {
    display: inline-block;
  }

  del {
      display: inline-block;
      text-decoration: none;
      position: relative;

      &::after {
         content: "";
         position: absolute;
         top: calc(50% + 1px);
         left: 0;
         display: block;
         width: 100%;
         height: 2px;
         background: #fff;
         transform: translateY(-50%);
      }
  }
`;

export default GetBlack;
