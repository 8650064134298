/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link as RSLink } from 'react-scroll';
import styled, { keyframes, css } from 'styled-components';
import SocialMedia from 'components/SocialMedia';
import Logo from 'components/Logo';
import devices from 'theme/devices';
import { Link } from 'gatsby';

export const sectionsId = {
  start: 'start',
  getBlack: 'getBlack',
  portfolio: 'portfolio',
  whyBlack: 'whyBlack',
  tellAStory: 'tellAStory',
};

interface NavigationProps {
  enNavAnimation: () => void;
  noPortfolioCases: boolean;
  navGetBlack: string;
  navPortfolio: string;
  navTellAStory: string;
  navWhyBlack: string;
  langButton: string;
  lang: string;
  withAnimation: boolean;
}

interface NavStyledProps {
  sticky: boolean;
  withAnimation: boolean;
}

function Navigation({
  enNavAnimation,
  noPortfolioCases,
  navGetBlack,
  navPortfolio,
  navTellAStory,
  navWhyBlack,
  langButton,
  lang,
  withAnimation,
}: NavigationProps) {
  const [smallHeader, setSmallHeader] = useState(false);

  const handleAnimation = () => {
    setSmallHeader(window.scrollY > (window.innerHeight / 2));
  };

  useEffect(() => {
    handleAnimation();
    document.addEventListener('scroll', handleAnimation);
    window.addEventListener('resize', handleAnimation);
    return () => {
      document.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', handleAnimation);
    };
  }, []);

  return (
    <Nav sticky={smallHeader} withAnimation={withAnimation} onAnimationEnd={enNavAnimation}>
      <SocialMedia white />
      <StyledLogoLink to={sectionsId.start} spy smooth>
        <Logo version="white" />
      </StyledLogoLink>
      <NavList>
        <NavItem>
          <NavItemLink
            activeClass="active"
            to={sectionsId.getBlack}
            offset={-100}
            smooth
            spy
          >
            {navGetBlack}
          </NavItemLink>
        </NavItem>
        {!noPortfolioCases && (
        <NavItem>
          <NavItemLink
            activeClass="active"
            to={sectionsId.portfolio}
            offset={-100}
            smooth
            spy
          >
            {navPortfolio}
          </NavItemLink>
        </NavItem>
        )}
        <NavItem>
          <NavItemLink
            activeClass="active"
            to={sectionsId.whyBlack}
            offset={-100}
            smooth
            spy
          >
            {navWhyBlack}
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink
            activeClass="active"
            to={sectionsId.tellAStory}
            offset={-100}
            smooth
            spy
          >
            {navTellAStory}
          </NavItemLink>
        </NavItem>
      </NavList>
      <StyledLangLink to={lang === 'en' ? '/' : '/en'}>{langButton}</StyledLangLink>
    </Nav>
  );
}

const StyledLogoLink = styled(RSLink)`
  display: block;
  padding: 20px;
  margin: 0;
  text-decoration: none;
  transition: padding 0.35s;
  cursor: pointer;

  ${devices.mobile} {
    padding: 24px 30px;
  }

  ${devices.mobileL} {
    padding: 30px 40px;
  }

  ${devices.tablet} {
    padding: 40px;
  }

  ${devices.tabletXL} {
    padding: 60px;
  }
`;

const NavList = styled.ul`
   padding: 0;
   margin: 0;
   list-style-type: none;
   display: none;

   ${devices.tablet} {
     display: flex;
   }
`;

const NavItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
  transition: padding 0.35s;

  ${devices.tabletXL} {
    padding: 20px;
  }

  ${devices.desktop} {
    padding: 20px 40px;
  }
`;

const NavItemLink = styled(RSLink)`
  display: block;
  font-size: 15px;
  font-weight: 700;
  padding: 40px 20px;
  transition: padding 0.35s;
  cursor: pointer;

  &.active {
    text-decoration: underline;
  }

  ${devices.tabletXL} {
    padding: 40px 20px;
  }
`;

const StyledLangLink = styled(Link)`
  display: block;
  padding: 20px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  transition: padding 0.35s;

  &:hover {
    text-decoration: underline;
  }

  ${devices.mobile} {
    padding: 24px 30px;
  }

  ${devices.mobileL} {
    padding: 30px 40px;
  }

  ${devices.tablet} {
    padding: 40px;
    font-size: 15px;
  }

  ${devices.tabletXL} {
    padding: 60px;
  }
`;

const intro = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Nav = styled.nav<NavStyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #000;
  max-height: 100%;

  ${({ withAnimation }) => withAnimation && css`
    opacity: 0;
    animation: ${intro} 1s 6s forwards;
  `}

  ${({ sticky }) => sticky && css`
    ${StyledLangLink}, ${StyledLogoLink} {
      padding: 14px 20px;
    }

    ${devices.mobile} {
      ${StyledLangLink}, ${StyledLogoLink} {
        padding: 16px 30px;
      }
    }

    ${devices.mobileL} {
      ${StyledLangLink}, ${StyledLogoLink} {
        padding: 20px 40px;
      }
    }

    ${devices.tablet} {
      ${StyledLangLink}, ${StyledLogoLink} {
        padding: 30px 40px;
      }

      ${NavItemLink} {
        padding: 30px 20px;
      }
    }

    ${devices.tabletXL} {
      ${StyledLangLink}, ${StyledLogoLink} {
        padding: 30px 60px;
      }

      ${NavItemLink} {
        padding: 30px 20px;
      }

      ${NavItem} {
        padding: 0 20px;
      }
    }

    ${devices.desktop} {
      ${NavItem} {
        padding: 0 40px;
      }
    }
  `}
`;

export default Navigation;
