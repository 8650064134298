import React, { useState, useEffect } from 'react';
import { Link as RSLink } from 'react-scroll';
import styled, { keyframes, css } from 'styled-components';
import Logo from 'components/Logo';
import SocialMedia from 'components/SocialMedia';
import { Link } from 'gatsby';

export const sectionsId = {
  start: 'start',
  getBlack: 'getBlack',
  portfolio: 'portfolio',
  whyBlack: 'whyBlack',
  tellAStory: 'tellAStory',
};

interface MobileNavigationProps {
  enNavAnimation: () => void;
  withAnimation: boolean;
  noPortfolioCases: boolean;
  navGetBlack: string;
  navPortfolio: string;
  navTellAStory: string;
  navWhyBlack: string;
  langButton: string;
  lang: string;
}

interface NavStyledProps {
  sticky: boolean;
  withAnimation: boolean;
}

function MobileNavigation({
  enNavAnimation,
  withAnimation,
  noPortfolioCases,
  navGetBlack,
  navPortfolio,
  navTellAStory,
  navWhyBlack,
  langButton,
  lang,
}: MobileNavigationProps) {
  const [smallHeader, setSmallHeader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);

  const handleAnimation = () => {
    setSmallHeader(window.scrollY > (window.innerHeight / 2));
  };

  useEffect(() => {
    handleAnimation();

    document.addEventListener('scroll', handleAnimation);
    window.addEventListener('resize', handleAnimation);
    return () => {
      document.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', handleAnimation);
    };
  }, []);

  return (
    <Nav sticky={smallHeader} withAnimation={withAnimation} onAnimationEnd={enNavAnimation}>
      <StyledLogoLink to={sectionsId.start} spy smooth>
        <Logo version="white" />
      </StyledLogoLink>
      <Burger
        type="button"
        isActive={open}
        onClick={() => setOpen((prev) => !prev)}
      />
      <Menu open={open}>
        <NavList>
          <NavItem>
            <NavItemLink
              activeClass="active"
              to={sectionsId.getBlack}
              onClick={() => setOpen(false)}
              offset={-100}
              smooth
              spy
            >
              {navGetBlack}
            </NavItemLink>
          </NavItem>
          {!noPortfolioCases && (
            <NavItem>
              <NavItemLink
                activeClass="active"
                onClick={() => setOpen(false)}
                to={sectionsId.portfolio}
                offset={-100}
                smooth
                spy
              >
                {navPortfolio}
              </NavItemLink>
            </NavItem>
          )}
          <NavItem>
            <NavItemLink
              activeClass="active"
              onClick={() => setOpen(false)}
              to={sectionsId.whyBlack}
              offset={-100}
              smooth
              spy
            >
              {navWhyBlack}
            </NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink
              activeClass="active"
              onClick={() => setOpen(false)}
              to={sectionsId.tellAStory}
              offset={-100}
              smooth
              spy
            >
              {navTellAStory}
            </NavItemLink>
          </NavItem>
        </NavList>
      </Menu>
      <StyledLangLink to={lang === 'en' ? '/' : '/en'} open={open}>{langButton}</StyledLangLink>
      <StyledSocialMedia white open={open} />
    </Nav>
  );
}

const StyledSocialMedia = styled(SocialMedia)<{open: boolean}>`
   transform: translateX(calc(100% + 15px));
   transition: transform 0.35s;

   ${({ open }) => open && css`
      transform: translateX(0);
      transition: transform 0.35s 0.35s;
  `}

`;

const Burger = styled.button<{isActive: boolean}>`
  position: relative;
  display: block;
  width: 70px;
  height: 50px;
  padding: 10px 20px;
  border: none;
  margin: 0;
  background: none;
  transition: transform 0.35s ease-in-out;
  z-index: 100;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    transition: transform 0.35s ease-in-out;
  }

  &::before {
    transform: translate(-50%, calc(-50% - 3px));
  }

  &::after {
    transform: translate(-50%, calc(-50% + 3px));
  }

  ${({ isActive }) => isActive
    && css`
      transform: rotate(180deg);

      &::before {
        transform: translate(-50%, calc(-50%)) rotate(-45deg) scaleX(0.65);
      }

      &::after {
        transform: translate(-50%, calc(-50%)) rotate(45deg) scaleX(0.65);
      }
    `}
`;

const Menu = styled.div<{open: boolean}>`
   position:fixed;
   top: 0;
   left: 100%;
   bottom: 0;
   background: #000;
   width: 100%;
   z-index: 99;
   transition: 0.35s ease-in-out;

   ${({ open }) => open && css`
      transform: translateX(-100%);
   `}
`;

const StyledLogoLink = styled(RSLink)`
   position: relative;
   z-index: 100;
  display: block;
  padding: 20px;
  margin: 0;
  text-decoration: none;
  transition: padding 0.35s;
  cursor: pointer;
`;

const NavList = styled.ul`
   padding: 0;
   margin: 0;
   list-style-type: none;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
`;

const NavItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
  transition: padding 0.35s;
`;

const NavItemLink = styled(RSLink)`
  display: block;
  font-size: 15px;
  font-weight: 700;
  padding: 12px;
  transition: padding 0.35s;
  cursor: pointer;

  &.active {
    text-decoration: underline;
  }
`;

const StyledLangLink = styled(Link)<{open: boolean, }>`
   display: block;
   text-decoration: none;
   font-size: 14px;
   font-weight: 700;
   transition: padding 0.35s;
   position:fixed;
   bottom: -24px;
   left: 10px;
   z-index: 99;
   transform: rotate(-90deg) translateY(-100%);
   transform-origin: left top;
   padding: 10px;
   transition: transform 0.35s;

  &:hover {
    text-decoration: underline;
  }

  ${({ open }) => open && css`
      transform: rotate(-90deg) translateY(0);
      transition: transform 0.35s 0.35s;
  `}
`;

const intro = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Nav = styled.nav<NavStyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  max-height: 100%;


  ${({ withAnimation }) => withAnimation && css`
    opacity: 0;
    animation: ${intro} 1s 6s forwards;
  `}

  ${({ sticky }) => sticky && css`
   ${StyledLogoLink} {
      padding: 14px 20px;
    }
  `}
`;

export default MobileNavigation;
