import React from 'react';
import styled from 'styled-components';
import devices from 'theme/devices';
import { graphql, useStaticQuery } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { sectionsId } from 'layouts/StartTemplate/Navigation/Navigation';

const query = graphql`
   {
      allFile(filter: {name: {in: ["whyBlackBrush", "whyBlackLogos", "whyBlackSmile", "whyBlackWhat", "whyBlackBackground"]}}) {
         nodes {
            name
            publicURL
         }
      }
   }
`;

interface MediaItem {
   publicURL: string;
   name: string;
}

interface WhyBlackProps {
   noBullshit: any;
   cityConnection: any;
}

function City({ noBullshit, cityConnection }: WhyBlackProps) {
  const { allFile } = useStaticQuery<{ allFile: { nodes: MediaItem[] } }>(query);

  return (
    <Wrapper id={sectionsId.whyBlack}>
      <VideoWrapper>
        <Video autoPlay muted loop id="myVideo">
          <source src={allFile.nodes.find((node: MediaItem) => node.name === 'whyBlackBackground')?.publicURL || ''} type="video/mp4" />
        </Video>
      </VideoWrapper>
      <Content>
        <PlusTopLeft />
        <PlusCenterLeft />
        <PlusBottomLeft />
        <PlusTopRight />
        <PlusCenterRight />
        <PlusBottomRight />
        <InnerContent>
          <WhyBlack>
            <WhyGroup>
              <WhyText>
                <span>Why</span>
                {' '}
                <WhyIcon src={allFile.nodes.find((node: MediaItem) => node.name === 'whyBlackWhat')?.publicURL || ''} alt="" />
              </WhyText>
            </WhyGroup>
            <BlackText>BLACK?</BlackText>
          </WhyBlack>
          <Row>
            <Logos>
              <LogosVector src={allFile.nodes.find((node: MediaItem) => node.name === 'whyBlackLogos')?.publicURL || ''} alt="" />
            </Logos>
            <DescriptionWrapper>
              <Description>
                <MainText>
                  <StructuredText data={cityConnection} />
                </MainText>
                <NoBullshit>
                  <span>
                    <StructuredText data={noBullshit} />
                  </span>
                  <BrushWrapper>
                    <Brush src={allFile.nodes.find((node: MediaItem) => node.name === 'whyBlackBrush')?.publicURL || ''} alt="" />
                  </BrushWrapper>
                </NoBullshit>
                <Smile src={allFile.nodes.find((node: MediaItem) => node.name === 'whyBlackSmile')?.publicURL || ''} alt="" />
              </Description>
            </DescriptionWrapper>
          </Row>
        </InnerContent>
      </Content>
    </Wrapper>
  );
}

const Row = styled.div`
  ${devices.mobile} {
    display: flex;
    align-items: center;
  }
`;

const InnerContent = styled.div`
  display: block;
  max-width: 688px;
`;

const Plus = styled.span`
  display: block;
  z-index: 3;
  position: absolute;
  height: 22px;
  width: 22px;

  ${devices.tablet} {
    height: 40px;
    width: 40px;
  }

  ${devices.tabletL} {
    width: 62px;
    height: 62px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 2px;
    background: #fff;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const PlusTopLeft = styled(Plus)`
  top: 0;
  left: 0;
`;

const PlusCenterLeft = styled(Plus)`
  top: calc(50% - 1px);
  left: 0;
`;

const PlusBottomLeft = styled(Plus)`
  bottom: 0;
  left: 0;
`;

const PlusTopRight = styled(Plus)`
  top: 0;
  right: 0;
`;

const PlusCenterRight = styled(Plus)`
  top: calc(50% - 1px);
  right: 0;
`;

const PlusBottomRight = styled(Plus)`
  bottom: 0;
  right: 0;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  padding: 27px;

  ${devices.mobile} {
    padding: 40px;
  }

  ${devices.tablet} {
    padding: 72px;
    max-width: 863px;
  }

  ${devices.tabletL} {
    padding: 94px;
  }
`;

const Video = styled.video`
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
`;

const MainText = styled.div`
   color: #000;
`;

const NoBullshit = styled.div`
   color: #000;
   position: realative;
   z-index: 0;
   display: block;
   font-weight: 700;

   > span > p {
      margin: 0;
   }
`;

const Smile = styled.img`
  display: block;
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 50px;

  ${devices.mobileL} {
    width: 70px;
    bottom: -25px;
    right: -25px;
  }

  ${devices.tablet} {
    width: 120px;
    bottom: -50px;
    right: -50px;
  }

  ${devices.tabletL} {
    width: 135px;
    bottom: -60px;
    right: -60px;
  }
`;

const DescriptionWrapper = styled.div`
  background: #fff;
`;

const Description = styled.div`
  position: relative;
  z-index: 2;
  padding: 20px;
  font-size: 14px;

  ${devices.mobileL} {
    line-height: 1.4;
    letter-spacing: 0.025em;
  }

  ${devices.tabletL} {
    padding: 40px;
  }
`;

const BrushWrapper = styled.div`
  position: absolute;
  z-index: -1;
  left: 5px;
  bottom: 10px;

  ${devices.tabletL} {
    left: 15px;
    bottom: 30px;
  }
`;

const Brush = styled.img`
  display: block;
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 60px 20px;
  overflow: hidden;

  ${devices.tabletL} {
    padding: 100px 40px;
  }

  ${devices.desktop} {
    padding: 160px 160px 80px;
  }
`;

const WhyGroup = styled.div`
  position: relative;
`;

const WhyIcon = styled.img`
  position: absolute;
  top: calc(50% - 5px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: calc(100% + 2rem);

  ${devices.tablet} {
    width: calc(100% + 80px);
    top: calc(50% - 10px);
  }
`;

const WhyBlack = styled.div``;

const WhyText = styled.span`
  position: relative;
  display: inline-block;
  line-height: 0.75;
  color: #fff;
  font-size: 16vw;
  font-weight: 700;
  padding: 0.5rem 1rem 1rem 0;
  background: #000;

  ${devices.tablet} {
    font-size: 140px;
  }

  ${devices.tabletL} {
    font-size: 150px;
  }
`;

const BlackText = styled.span`
  position: relative;
  display: inline-block;
  line-height: 0.75;
  margin-top: -1rem;
  color: #fff;
  font-size: 16vw;
  font-weight: 700;
  padding-bottom: 2rem;

  ${devices.tablet} {
    font-size: 140px;
  }

  ${devices.tabletL} {
    font-size: 150px;
    padding-bottom: 60px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    display: inline-block;
    top: 0.4rem;
    left: 0;
    width: calc(100% + 2rem);
    height: calc(100% - 0.4rem);
    background: #000;
  }
`;

const Logos = styled.div`
  ${devices.mobile} {
    flex-shrink: 0;
    padding: 18px;
  }
`;

const LogosVector = styled.img`
  display: block;
  width: 100%;
  max-width: 225px;

  ${devices.mobile} {
    max-width: 160px;
  }

  ${devices.mobileL} {
    max-width: 225px;
  }
`;

export default City;
